$(document).ready(function () {
  $('.home_main_banner .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    slideSpeed: 5000,
    autoplay: true,
    animateOut: 'fadeOut',
    thumbs: true,
    thumbsPrerendered: true
  });

  $('a[href*="#"]')

    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function () {

            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            } else {
              $target.attr('tabindex', '-1');
              $target.focus();
            };
          });
        }
      }
    });

  $('.mobile_menu_btn, .menu_mask').on('click', function () {
    $('.header_menu_block').toggleClass('menu_show');
  });
  $('.header_menu_item').on('click', function () {
    $('.header_menu_block').removeClass('menu_show');
  });

  $('#scrollTop').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
  });

  $(window).scroll(function () {
    var window_scrollTop = $(window).scrollTop();
    if (window_scrollTop >= 300) {
      $('.header').addClass('fix_header');
      $('#scrollTop').addClass('fixed');
    } else {
      $('.header').removeClass('fix_header');
      $('#scrollTop').removeClass('fixed');
    };
    scrollnumber('.home_min_title', 'ani_start', 50);
    scrollnumber('.home_event_content', 'ani_start', 50);
    scrollnumber('.home_feature_info', 'ani_start', 50);
    scrollnumber('[data-product-item=dream]', 'ani_start', 30);
    scrollnumber('[data-product-item=luxury]', 'ani_start', 30);
    scrollnumber('[data-product-item=nature]', 'ani_start', 30);
    scrollnumber('[data-product-item=color]', 'ani_start', 30);
    scrollnumber('[data-product-item=fli]', 'ani_start', 30);
    scrollnumber('[data-product-item=mystic]', 'ani_start', 30);
  });

  $('[data-item="link"]').on('click', function (e) {
    $(this).addClass('show');
    $(this).siblings().removeClass('show');
    hrefTemp = $(this).children('a').attr('href');
    $('[data-item-name="' + hrefTemp + '"]').addClass('active');
    $('[data-item-name="' + hrefTemp + '"]').siblings().removeClass('active');
    e.preventDefault();
  });

  var firstProduct = $('#product').val();
  var firstProductText = $('#product :selected').text();

  $('#customProductSelect').text(firstProductText);
  if (firstProduct) {
    $('.search_product_imgs').find('.search_product_img[data-product=' + firstProduct + ']').addClass('show');
  } else {
    $('#customProductSelect').addClass('not_selected');
  };

  $('#product').on('change', function() {
    var product = $(this).val();
    var text = $('#product :selected').text();
    $('#customProductSelect').removeClass('not_selected');
    $('#customProductSelect').text(text);
    $('.search_product_img').removeClass('show');
    $('.search_product_imgs').find('.search_product_img[data-product=' + product + ']').addClass('show');
  });

  $('.line_at_close').on('click', function(){
    $('.line_at').hide();
  });
});

function scrollnumber(element, cssname, offset) {
  if ($(element).length >= 1) {
    var a, b, c, d;
    d = $(element).offset().top;
    a = eval(d + offset);
    b = $(window).scrollTop();
    c = $(window).height();
    if (b + c > a && $(element).length >= 1) {
      $((element)).addClass((cssname));
    }
  }
}
