// CSS Style
import 'styles/client/vendors/animate.min.css';
import 'styles/client/vendors/owl.carousel.min.css';
import 'styles/client';

// Other JS
import WOW from 'wow.js';
import 'client/owl.carousel.min';
import 'client/owl.carousel2.thumbs';
import TwCitySelector from 'tw-city-selector';
import 'client/custom';

require('@rails/ujs').start();

// 因為在 webpack 打包的時候，沒有瀏覽器的 window 物件，所以必須 assign 這樣才能在 rails view 使用
// Ref: https://tinyurl.com/ycrtavmd
window.jQuery = $;
window.$ = $;
window.TwCitySelector = TwCitySelector;

$(document).ready(function () {
  var wow = new WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: false,
    live: false,
    scrollContainer: null
  });
  wow.init();

});
